import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'jquery-sticky';
// import ScrollReveal from 'scrollreveal';

export default {
	init() {
		$('#navbar').sticky({
			topSpacing: 0,
			zIndex: 9999,
		});

		$('#mobile-nav-header').sticky({
			topSpacing: 0,
			zIndex: 100,
		});

		$('.search-btn').on('click', function (e) {
			e.preventDefault();
			$('.search-popout').slideToggle();
		});

		$('.hide-search').on('click', function (e) {
			e.preventDefault();
			$('.search-popout').slideUp();
		});

		$('.offer-popup-close').on('click', function (e) {
			e.preventDefault();
			$(this).parent().fadeOut();
		});

		setTimeout(function () {
			$('.popup').fadeIn();
		}, 5000);

		$('.swatches-popup-close').on('click', function (e) {
			e.preventDefault();
			$(this).parent().addClass('close');
			$(this).parent().removeClass('show');
		});

		$('#testimonials__main-slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			rows: 0,
			pauseOnHover: true,
			slide: '#testimonials__main-slider-item',
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 3000,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 3,
					},
				},
			],
		});

		$('#testimonials__main-slider-single').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			pauseOnHover: true,
			rows: 0,
			slide: '#testimonials__main-slider-item-single',
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 3000,
		});

		$('.burger-menu button').on('click', function () {
			$('.mobile-nav__menu').slideToggle();
			$('.burger-menu').toggleClass('active');
			$('body').toggleClass('no-scroll');
		});

		$('.mobile-banner-slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: false,
			rows: 0,
			slide: '.mobile-banner-slider__item',
			arrows: false,
			dots: false,
			autoplay: true,
			autoplaySpeed: 2500,
		});

		if ($(window).width() <= 1024) {
			$('.mobile-dropdown-header').on('click', function () {
				$(this).parent().find('nav').slideToggle();
				$(this).toggleClass('border-bottom');
			});
		}

		if ($(window).width() <= 750) {
			$('.double-hero__main').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: false,
				rows: 0,
				dots: true,
				arrows: false,
				autoplay: false,
				autoplaySpeed: 3000,
			});
		}

		if ($(window).width() < 1280) {
			$('.why-chelsea__main-slider').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: false,
				rows: 0,
				slide: '.why-chelsea__main-right-item',
				dots: false,
				arrows: true,
				prevArrow: $('.prev-single'),
				nextArrow: $('.next-single'),
				autoplay: false,
				autoplaySpeed: 3000,
			});
		}

		if ($(window).width() < 1280) {
			$('.most-popular__main-right ul').slick({
				slidesToShow: 1.1,
				slidesToScroll: 1,
				infinite: false,
				rows: 0,
				arrows: false,
				slide: '.type-product',
				dots: true,
				appendDots: '.most-popular__main',
				autoplay: false,
				autoplaySpeed: 3000,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 720,
						settings: {
							slidesToShow: 2,
						},
					},
					{
						breakpoint: 900,
						settings: {
							slidesToShow: 3,
						},
					},
				],
			});
		}
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
		// $(window).on('load', function () {
		// 	var loadTime =
		// 		window.performance.timing.domContentLoadedEventEnd -
		// 		window.performance.timing.navigationStart;
		// 	var timeout = 0;
		// 	if (loadTime < 1000) {
		// 		timeout = 1000 - loadTime;
		// 	}
		// 	setTimeout(function () {
		// 		document
		// 			.querySelector('.loading-screen')
		// 			.classList.remove('active');
		// 	}, timeout);
		// 	if ($(window).width() >= 1024) {
		// 		let sr = ScrollReveal();
		// 		sr.reveal('.fade', { scale: '1', distance: '0px', delay: 250 });
		// 		sr.reveal('.fade-in', {
		// 			duration: 450,
		// 			origin: 'bottom',
		// 			scale: '1',
		// 			delay: 250,
		// 		});
		// 		sr.reveal('.fade-in-app', {
		// 			duration: 450,
		// 			origin: 'bottom',
		// 			scale: '1',
		// 			delay: 500,
		// 		});
		// 		sr.reveal('.fade-down', {
		// 			duration: 450,
		// 			origin: 'top',
		// 			scale: '1',
		// 			delay: 250,
		// 		});
		// 		sr.reveal('.fade-left', {
		// 			duration: 450,
		// 			origin: 'left',
		// 			scale: '1',
		// 			delay: 250,
		// 		});
		// 		sr.reveal('.fade-right', {
		// 			duration: 450,
		// 			origin: 'right',
		// 			scale: '1',
		// 			delay: 250,
		// 		});
		// 		sr.reveal('.fade-up', {
		// 			distance: '100px',
		// 			easing: 'ease-in',
		// 			duration: 450,
		// 			origin: 'bottom',
		// 			scale: '1',
		// 			delay: 250,
		// 		});
		// 		sr.reveal('.fade-up-2', {
		// 			duration: 450,
		// 			origin: 'bottom',
		// 			scale: '1',
		// 			delay: 250,
		// 		});
		// 		sr.reveal(
		// 			'.fade-up-seq',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'bottom',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-up-seq-collection',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'bottom',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-up-seq-btn',
		// 			{
		// 				distance: '50px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'bottom',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-up-seq-about',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'bottom',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal('.fade-in-left', {
		// 			distance: '100px',
		// 			easing: 'ease-in',
		// 			duration: 450,
		// 			origin: 'left',
		// 			scale: '1',
		// 			delay: 250,
		// 		});
		// 		sr.reveal('.fade-in-right', {
		// 			distance: '100px',
		// 			easing: 'ease-in',
		// 			duration: 450,
		// 			origin: 'right',
		// 			scale: '1',
		// 			delay: 250,
		// 		});
		// 		sr.reveal(
		// 			'.fade-in-seq-left',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'left',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-in-seq',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'left',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-up-shop',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'bottom',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-up-seq-b',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'bottom',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-up-seq-wwo',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'bottom',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-in-seq-right',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'right',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-in-seq-team',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'bottom',
		// 				interval: 100,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 		sr.reveal(
		// 			'.fade-down-seq-pins',
		// 			{
		// 				distance: '100px',
		// 				easing: 'ease-in',
		// 				duration: 450,
		// 				origin: 'top',
		// 				interval: 200,
		// 				scale: '1',
		// 				delay: 250,
		// 			},
		// 			100
		// 		);
		// 	}
		// });
	},
};
