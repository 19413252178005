export default {
	init() {
		$('body').on('change', 'input.swatch-checkbox', function () {
			var $checked = $('input.swatch-checkbox:checked');

			if ($checked.length >= 5) {
				$(this).prop('checked', false);
				$('.swatches-popup').addClass('show');
			} else {
				var chosen_swatches = [];

				$('input.swatch-checkbox:checked').each(function () {
					var swatch = {
						id: $(this).attr('id'),
						img: $(this).next('label').find('div').data('img'),
					};

					chosen_swatches.push(swatch);
				});

				var empty_slots = 4 - chosen_swatches.length;
				$('.chosen-swatches').html('');

				$(chosen_swatches).each(function (i, swatch) {
					$('.chosen-swatches').append(
						'<li class="taken" data-input="#' +
							swatch.id +
							'">' +
							'<span class="swatch-img" style="background-image: url(' +
							swatch.img +
							');"></span>' +
							'</li>'
					);
				});

				var $i;
				for ($i = 0; $i < empty_slots; $i++) {
					$('.chosen-swatches').append(
						'<li>' + '<span class="swatch-img"></span>' + '</li>'
					);
				}
			}

			$('.taken').on('click', function () {
				var input_id = $(this).attr('data-input');
				$(input_id).trigger('click');
			});
		});

		$('#swatches-form').submit(function (e) {
			// dont submit form properly
			e.preventDefault();
			const ajaxurl = $('input[name=ajaxurl]').val();

			// get all form data and serialze it.
			var form_data = $(this).serializeArray();

			$.ajax({
				type: 'POST',
				url: ajaxurl,
				data: form_data,

				success: function (ajax_data) {
					// convert the json encoded response to a javascript array
					//var response = JSON.parse(ajax_data);
					var response = ajax_data;
					//console.log(response);

					/*
					 *  To debug the ajax response uncomment line below
					 */

					if (response.status == 'success') {
						// if there was any errors... hide them now.
						$('.message__error').slideUp(400);
						// replace form html with the ajax response message
						$('.swatches-page__main-form .inner__form').html(
							response.message
						);
					} else {
						// add error message before form.
						$('.swatches-page__main-form .inner__form').append(
							response.message
						);
					}
				},
			});
		});

		$(document).ajaxStart(function () {
			$('.swatches-page__main-form').addClass('submitted');
		});

		$(document).ajaxComplete(function () {
			$('.swatches-page__main-form').removeClass('submitted');
		});
	},
	finalize() {
		// JavaScript to be fired after the init JS
	},
};
