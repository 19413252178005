export default {
	init() {
		// JavaScript to be fired on the contact us page

		$('#contact-form').on('submit', function (e) {
			// dont submit form properly
			e.preventDefault();
			$('#contact-form').addClass('ajaxing');

			const ajaxurl = $('input[name=ajaxurl]').val();

			// get all form data and serialze it.
			var form_data = $(this).serializeArray();

			$.ajax({
				type: 'POST',
				url: ajaxurl,
				data: form_data,

				success: function (response) {
					console.log(response);

					/*
					 *  To debug the ajax response uncomment line below
					 */

					if (response.status == 'success') {
						// if there was any errors... hide them now.
						$('#contact-form').html(response.html);
					} else {
						// add error message before form.
						$('#messages').html(response.html);
					}

					$('#contact-form').removeClass('ajaxing');
				},
			});
		});
	},
};
