import 'slick-carousel';

export default {
	init() {
		$('.single-post__slider').slick({
			slidesToShow: 1.42,
			slidesToScroll: 1,
			infinite: false,
			rows: 0,
			slide: '.single-post__slider-item',
			arrows: true,
			dots: false,
			appendArrows: '.arrows',
			prevArrow: $('.prev-single'),
			nextArrow: $('.next-single'),
		});

		
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
