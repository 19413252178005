import 'slick-carousel';

export default {
	init() {
		$('.front-banner__slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: false,
			rows: 0,
			slide: '.front-banner__slider-item',
			arrows: false,
			dots: true,
			fade: true,
			autoplay: true,
			autoplaySpeed: 2500,
			cssEase: 'linear',
			customPaging: function (slick, index) {
				var page = slick.$slides.eq(index).attr('data-paging');
				return (
					'<a>' +
					'0' +
					(index + 1) +
					'<span>' +
					page +
					'</span>' +
					'</a>'
				);
			},
		});

		if ( $(window).width() >= 1024 ) { 
			$('.explore-tab').click(function() {
				$('html, body').animate({
					scrollTop: $('.our-collection').offset().top - 150,
				}, 2000);
			});
		} else {
			$('.explore-tab').click(function() {
				$('html, body').animate({
					scrollTop: $('.our-collection').offset().top - 70,
				}, 2000);
			});
		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
