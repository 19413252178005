export default {
	init() {
		$('.question').on('click', function () {
			var slide_up_time = 200;
			var slide_down_time = 400;
			var $question = $(this);
			var $response = $question.next('.answer');
	
			if ($response.is(':visible')) {
				$response.slideUp(slide_up_time);
			} else {
				$response.slideUp(slide_up_time);
				$response.slideDown(slide_down_time);
			}

			$(this).find('svg').toggleClass('rotate');
		});
	},
	finalize() {
	// JavaScript to be fired on the home page, after the init JS
	},
};