export default {
	init() {
		$('.type').on('click', function () {
			var slide_up_time = 200;
			var slide_down_time = 400;
			var $question = $(this);
			var $content = $question.next('.content');

			if ($content.is(':visible')) {
				$content.slideUp(slide_up_time);
			} else {
				$content.slideUp(slide_up_time);
				$content.slideDown(slide_down_time);
			}

			$(this).find('svg').toggleClass('rotate');
		});

		if ($(window).width() <= 1024) {
			$('.buying-guide-page__price-prices').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: false,
				rows: 0,
				slide: '.item',
				dots: false,
				arrows: true,
				prevArrow: $('.prev-single'),
				nextArrow: $('.next-single'),
			});
		}
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
