import 'slick-carousel';

export default {
	init() {
		
		$('body').on('DOMSubtreeModified', '.single_variation_wrap', function(){
			let variation_price_html = $('.single_variation_wrap .woocommerce-variation-price').html();
			$('.single-product-wrapper .summary .price-wrapper .price').html(variation_price_html);
		});

		$('#swatch-toggle').on('click', function(e){
			e.preventDefault();
			let this_text = $(this).text();
			if(this_text == 'View our fabric swatches') {
				$(this).text('Hide fabric swatches');
			} else {
				$(this).text('View our fabric swatches');
			}
			$('#sing-prod-swatches').slideToggle(300);
		})
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
