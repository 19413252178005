export default {
  init() {
    // JavaScript to be fired on the about us page

    $('.explore-tab').click(function() {
      $('html, body').animate({
        scrollTop: $('.our-story').offset().top - 170,
      }, 2000);
    });
  },
};
