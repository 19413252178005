// import external dependencies
import 'jquery';

// Import everything from autoload
// import './autoload/**/*';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import about from './routes/about';
import faqs from './routes/faqs';
import buyingGuide from './routes/buyingGuide';
import swatches from './routes/swatches';
import single from './routes/single';
import singleProduct from './routes/singleProduct';
import contact from './routes/contact';

/** Populate Router instance with DOM routes */
const routes = new Router({
	// All pages
	common,
	// Home page
	home,
	// About page
	about,
	// FAQ page
	faqs,
	//Buying Guide Page
	buyingGuide,
	//Swatches Page
	swatches,
	//Single Post
	single,
	// Conact page
	contact,
	singleProduct,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
